@import "./../../../styles/variables.scss";

.StoreStep {
  margin-top: 10px;

  .searchStore {
    .Card {
      margin-top: 20px;
      align-items: center;
      margin-bottom: 10px;

      .card-content {
        h3 {
          margin-bottom: 0;
        }
      }
    }

    .searchByCap {
      display: flex;
      flex-direction: column-reverse;
      height: 61px;
      position: relative;

      label {
        position: absolute;
        top: 8px;
        display: none;
        color: $primary;
      }

      input[type="number"]::-webkit-inner-spin-button,
      input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      input {
        outline: none;
        width: 100%;
        height: 32px;
        border: none;
        border-bottom: 2px solid $border-color;
        background-color: transparent;
        font-size: 16px;
        padding-bottom: 8px;
      }

      &.complete label,
      input:focus ~ label {
        display: block !important;
      }

      .sbc-submit {
        bottom: 10px;
        right: 0;
        position: absolute;
        background-color: transparent;
        border: none;
        height: auto;

        &:after {
          font-family: "Font Awesome 5 Free";
          font-weight: 900;
          font-size: 20px;
          content: "\f002";
          display: block;
          color: $primary;
          cursor: pointer;
        }
      }
    }
  }

  .store-back {
    display: flex;
    align-items: center;
    flex: 0 0 100%;
    color: $primary;
    font-size: 16px;
    margin-top: 5px;
    margin-bottom: 10px;

    svg {
      width: 12px;
      height: auto;
      margin-right: 10px;
    }
  }

  & > p {
    display: block;
    margin-bottom: 8px;
    color: $text-gray;
  }
}
