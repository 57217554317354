@import "./../../styles/variables";

.cta-button {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 0 20px;
  border: 2px solid $primary;
  background-color: $primary;

  svg {
    margin-right: 15px;
  }
}
