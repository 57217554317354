@import "./../../../styles/variables";

.Card {
  display: flex;
  border: 2px solid $border-color;
  border-radius: 10px;
  padding: 15px 20px;
  box-shadow: 0 10px 10px -5px rgba(#00132d, 0.22);
  margin-bottom: 16px;
  cursor: pointer;

  &.selected, &:hover {
    background-color: $primary;

    .card-icon svg {
      color: $border-color;
    }
  }

  .card-icon {
    margin-right: 20px;

    svg {
      color: $primary;
      width: 21px;
      height: auto;
    }
  }

  .card-content {
    h3 {
      margin-bottom: 5px;
    }
  }
}
