@import "./../../../styles/variables";

.RegisterStep {
  margin-top: 5px;

  .form-element {
    display: flex;
    flex-direction: column-reverse;
    height: 61px;
    position: relative;

    label {
      position: absolute;
      top: 8px;
      display: none;
      color: $primary;
    }

    input {
      outline: none;
      width: 100%;
      height: 32px;
      border: none;
      border-bottom: 2px solid $border-color;
      background-color: transparent;
      font-size: 16px;
      padding-bottom: 12px;
    }

    &.complete label,
    input:focus ~ label {
      display: block !important;
    }
  }

  .Checkbox {
    margin-top: 20px;
  }

  .Button {
    margin-top: 10px;
  }
}