@import "./../../../styles/variables";

.ResumeBooking {
  margin-top: 25px;
  padding-left: 29px;
  padding-right: 30px;

  h1 {
    display: block;
    margin-bottom: 10px;
  }

  h3 {
    display: inline-block;
    line-height: 1.2;
  }

  .resumeBody {
    margin-top: 15px;
    border-top: 2px solid $border-color;
    border-bottom: 2px solid $border-color;
    padding: 10px 0;

    li {
      display: flex;
      align-items: flex-start;
      padding: 12px 0;

      .resumeProfile {
        overflow: hidden;
        width: 35px;
        height: 35px;
        background-color: rgba(0, 0, 0, 0.15);
        border-radius: 50%;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      svg {
        margin-left: 7px;
        margin-right: 8px;
        width: 20px;
        height: auto;
      }

      .resumeLiInfo {
        margin-left: 15px;

        .expert-on {
          strong:after {
            content: ",";
          }

          strong:last-child:after {
            content: "";
          }
        }
      }
    }
  }

  .resumeBack {
    cursor: pointer;
    margin-top: 25px;
    display: flex;
    align-items: center;
    color: $primary;

    svg {
      width: 15px;
      height: auto;
    }

    strong {
      padding-left: 10px;
      font-size: 16px;
    }
  }
}
