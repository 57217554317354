@import "./../../../styles/variables";

.Checkbox {
  display: flex;
  align-items: flex-end;
  margin-bottom: 12px;

  input {
    position: absolute;
    opacity: 0;
    margin: 0;

    &.error + label:before {
      border: 1px solid red;
    }

    &:checked + label:before {
      background-color: $primary;
      border: 1px solid $primary;
    }

    &:checked + label:after {
      content: "\f00c";
      font-family: "Font Awesome 5 Free";
      font-weight: 600;
      color: #fff;
      font-size: 8px;
      position: absolute;
      left: 4px;
      top: 4px;
    }
  }

  label {
    cursor: pointer;
    position: relative;
    margin-bottom: 0;
    font-size: 16px;
    display: flex;
    align-items: flex-end;

    &:before {
      content: "";
      margin-top: -3px;
      width: 16px;
      height: 16px;
      border-radius: 2px;
      border: 1px solid $primary;
      margin-right: 7px;
    }
  }

  a {
    margin-left: 5px;
    color: $primary;
    font-size: 12px;
    font-weight: 700;
    text-decoration: underline;
  }
}
