@import "./../../styles/variables";

.MainModal {
  position: relative;
  padding: 15px 10px;
  width: 375px;
  height: 610px;
  overflow-y: auto;
  border: 1px solid $border-color;
  background-color: #fff;

  /* @include respond-to("mobile") {
    width: 100%;
    height: 100%;
  } */

  .modal-step {
    margin-top: 20px;
  }

  .Step:last-child {
    padding-bottom: 0;
    border-left: none;
  }
}
