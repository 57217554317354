@import "./../../../styles/variables";

.PlatformStep {
  margin-top: 7px;

  & > p {
    display: block;
    margin-bottom: 10px;
    color: $text-gray;
  }
}