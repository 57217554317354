// Responsive breakpoints managar
@mixin respond-to($breakpoint) {
  // If the key exists in the map
  @if map-has-key($breakpoints, $breakpoint) {
    // Prints a media query based on the value
    @media (max-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  }
  // If the key doesn't exist in the map
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. " + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}

// Breakpoints
$breakpoints: (
  "mobile": 667px,
  /* "xs": 576px,
  "sm": 768px,
  "md": 992px,
  "lg": 1200px, */
);

// Colors
$primary: #ffc000;
$border-color: #000;

// Text colors
$text-dark: #000;
$text-gray: #7a8386;
$text-lightgray: #cdcdcd;

// Fonts
$body-font: "Open Sans", sans-serif;
$headings-font: "Rubik", sans-serif;
