@import "./../../../../../../styles/variables";

.Calendar {
  border-top: 2px solid $border-color;
  border-bottom: 2px solid $border-color;
  padding: 15px 5px;

  .row-month {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;

    svg {
      color: $primary;
      width: 12px;
      height: auto;
    }
  }

  .row-days {
    display: flex;
    justify-content: space-between;

    .col-day {
      text-align: center;

      &.active {
        span {
          background-color: $primary;
          border-radius: 50%;
        }
      }

      h3 {
        display: block;
        color: $text-gray;
        margin-bottom: 15px;
      }

      span {
        display: block;
        font-size: 16px;
        line-height: 30px;
        width: 30px;
        height: 30px;

        &.day-off {
          color: $text-lightgray;
        }
      }
    }
  }
}
