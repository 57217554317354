@import "./../../../styles/variables";

.Step {
  border-left: 1px solid $primary;
  padding-left: 18px;
  padding-right: 30px;
  margin-left: 11px;
  padding-bottom: 20px;

  &.done {
    .step-title {
      color: $text-lightgray;
    }
    .step-title:before {
      background-color: $primary;
    }
    .step-title:after {
      content: "\f00c";
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      display: block;
      color: #fff;
      font-size: 8px;
      position: absolute;
      top: 6px;
      left: -22px;
    }
  }

  &.open {
    .step-title {
      font-weight: 700;
      color: $text-dark;
    }
  }

  .step-title {
    position: relative;
    cursor: pointer;
    display: inline-block;
    color: $text-lightgray;
    font-weight: 500;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: -27px;
      display: block;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      border: 1px solid $primary;
      background-color: #fff;
    }
  }
}
