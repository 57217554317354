@import "./../../../../styles/variables";

.Store {
  margin-bottom: 20px;

  .Card {
    margin-bottom: 10px;
  }

  .maps-link {
    color: $primary;
    text-decoration: underline;
    font-size: 14px;
    font-weight: 400;
    font-family: $headings-font;
    margin-right: 15px;
  }
}
