@import "./../../../../../styles/variables";

.ExpertBook {
  margin-top: 20px;
  width: 100%;

  .no-slot {
    display: block;
    text-align: center;
    margin-top: 15px;
  }
}