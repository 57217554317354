@import "./../../../../styles/variables";

.Expert {
  margin-top: 22px;
  display: flex;
  flex-wrap: wrap;

  .expert-back {
    display: flex;
    align-items: center;
    flex: 0 0 100%;
    color: $primary;
    font-size: 16px;
    margin-bottom: 17px;

    svg {
      width: 12px;
      height: auto;
      margin-right: 10px;
    }
  }

  .expert-profile {
    overflow: hidden;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.1);
    margin-right: 15px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .expert-info {
    margin-right: 10px;

    .expert-on {
      strong:after {
        content: ",";
      }

      strong:last-child:after {
        content: "";
      }
    }

    .availability {
      display: block;
      margin-top: 5px;
      color: $text-gray;
    }
  }

  .expert-arrow {
    margin-left: auto;
    margin-top: 5px;

    svg {
      color: $primary;
      width: 13px;
      height: auto;
    }
  }
}
