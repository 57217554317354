@import "./styles/variables";
@import "./styles/reset";
@import "./styles/fonts";
@import "./styles/helpers";

body {
  font-family: $body-font;
  color: $text-dark;
  font-size: 12px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $headings-font;
}

h1 {
  font-size: 24px;
  font-weight: 700;
}

h2 {
  font-size: 18px;
  font-weight: 700;
}

h3 {
  font-size: 16px;
  font-weight: 500;
}

a {
  cursor: pointer;
  text-decoration: none;
}

p {
  line-height: 1.45;
}

button,
.button,
input[type="submit"] {
  outline: none;
  cursor: pointer;
  height: 46px;
  border-radius: 10px;
  font-size: 16px;
  font-family: $headings-font;
  font-weight: 500;
}

ul {
  list-style-type: none;
}

.text-gray {
  color: $text-gray;
}

.text-lightgray {
  color: $text-lightgray;
}
