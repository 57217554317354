@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,700;1,400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;700&display=swap);
@import url(https://use.fontawesome.com/releases/v5.3.1/css/all.css);
html,body,div,span,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,abbr,address,cite,code,del,dfn,em,img,ins,kbd,q,samp,small,strong,sub,sup,var,b,i,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,figcaption,figure,footer,header,hgroup,menu,nav,section,summary,time,mark,audio,video{margin:0;padding:0;border:0;outline:0;font-size:100%;vertical-align:baseline;background:transparent}body{line-height:1}article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block}nav ul{list-style:none}blockquote,q{quotes:none}blockquote:before,blockquote:after,q:before,q:after{content:"";content:none}a{margin:0;padding:0;font-size:100%;vertical-align:baseline;background:transparent}ins{background-color:#ff9;color:#000;text-decoration:none}mark{background-color:#ff9;color:#000;font-style:italic;font-weight:bold}del{text-decoration:line-through}abbr[title],dfn[title]{border-bottom:1px dotted;cursor:help}table{border-collapse:collapse;border-spacing:0}hr{display:block;height:1px;border:0;border-top:1px solid #000;margin:1em 0;padding:0}input,select{vertical-align:middle}*,*:before,*:after{box-sizing:border-box}.container{margin-right:auto;margin-left:auto}.c-pointer{cursor:pointer}.d-block{display:block}body{font-family:"Open Sans",sans-serif;color:#000;font-size:12px}h1,h2,h3,h4,h5,h6{font-family:"Rubik",sans-serif}h1{font-size:24px;font-weight:700}h2{font-size:18px;font-weight:700}h3{font-size:16px;font-weight:500}a{cursor:pointer;text-decoration:none}p{line-height:1.45}button,.button,input[type="submit"]{outline:none;cursor:pointer;height:46px;border-radius:10px;font-size:16px;font-family:"Rubik",sans-serif;font-weight:500}ul{list-style-type:none}.text-gray{color:#7a8386}.text-lightgray{color:#cdcdcd}

.cta-button{position:absolute;bottom:0;right:0;padding:0 20px;border:2px solid #ffc000;background-color:#ffc000}.cta-button svg{margin-right:15px}

.MainModal{position:relative;padding:15px 10px;width:375px;height:610px;overflow-y:auto;border:1px solid #000;background-color:#fff}.MainModal .modal-step{margin-top:20px}.MainModal .Step:last-child{padding-bottom:0;border-left:none}

.Header{display:flex;justify-content:space-between;align-items:center;border-bottom:2px solid #000}.Header h3{font-size:14px}.Header .close{text-align:center;width:28px;height:28px;line-height:30px;background-color:#000;cursor:pointer}.Header .close svg{color:#ffc000}

.ModeStep{margin-top:25px}

.Step{border-left:1px solid #ffc000;padding-left:18px;padding-right:30px;margin-left:11px;padding-bottom:20px}.Step.done .step-title{color:#cdcdcd}.Step.done .step-title:before{background-color:#ffc000}.Step.done .step-title:after{content:"\f00c";font-family:"Font Awesome 5 Free";font-weight:900;display:block;color:#fff;font-size:8px;position:absolute;top:6px;left:-22px}.Step.open .step-title{font-weight:700;color:#000}.Step .step-title{position:relative;cursor:pointer;display:inline-block;color:#cdcdcd;font-weight:500}.Step .step-title:before{content:"";position:absolute;top:0;left:-27px;display:block;width:18px;height:18px;border-radius:50%;border:1px solid #ffc000;background-color:#fff}

.Card{display:flex;border:2px solid #000;border-radius:10px;padding:15px 20px;box-shadow:0 10px 10px -5px rgba(0,19,45,0.22);margin-bottom:16px;cursor:pointer}.Card.selected,.Card:hover{background-color:#ffc000}.Card.selected .card-icon svg,.Card:hover .card-icon svg{color:#000}.Card .card-icon{margin-right:20px}.Card .card-icon svg{color:#ffc000;width:21px;height:auto}.Card .card-content h3{margin-bottom:5px}

.StoreStep{margin-top:10px}.StoreStep .searchStore .Card{margin-top:20px;align-items:center;margin-bottom:10px}.StoreStep .searchStore .Card .card-content h3{margin-bottom:0}.StoreStep .searchStore .searchByCap{display:flex;flex-direction:column-reverse;height:61px;position:relative}.StoreStep .searchStore .searchByCap label{position:absolute;top:8px;display:none;color:#ffc000}.StoreStep .searchStore .searchByCap input[type="number"]::-webkit-inner-spin-button,.StoreStep .searchStore .searchByCap input[type="number"]::-webkit-outer-spin-button{-webkit-appearance:none;margin:0}.StoreStep .searchStore .searchByCap input{outline:none;width:100%;height:32px;border:none;border-bottom:2px solid #000;background-color:transparent;font-size:16px;padding-bottom:8px}.StoreStep .searchStore .searchByCap.complete label,.StoreStep .searchStore .searchByCap input:focus ~ label{display:block !important}.StoreStep .searchStore .searchByCap .sbc-submit{bottom:10px;right:0;position:absolute;background-color:transparent;border:none;height:auto}.StoreStep .searchStore .searchByCap .sbc-submit:after{font-family:"Font Awesome 5 Free";font-weight:900;font-size:20px;content:"\f002";display:block;color:#ffc000;cursor:pointer}.StoreStep .store-back{display:flex;align-items:center;flex:0 0 100%;color:#ffc000;font-size:16px;margin-top:5px;margin-bottom:10px}.StoreStep .store-back svg{width:12px;height:auto;margin-right:10px}.StoreStep>p{display:block;margin-bottom:8px;color:#7a8386}

.Store{margin-bottom:20px}.Store .Card{margin-bottom:10px}.Store .maps-link{color:#ffc000;text-decoration:underline;font-size:14px;font-weight:400;font-family:"Rubik",sans-serif;margin-right:15px}

.Spinner,.Spinner:after{border-radius:50%;width:35px;height:35px}.Spinner{margin-top:15px;font-size:10px;position:relative;text-indent:-9999em;border-top:4px solid #fff;border-right:4px solid #fff;border-bottom:4px solid #ffc000;border-left:4px solid #ffc000;transform:translateZ(0);-webkit-animation:load8 1.1s infinite linear;animation:load8 0.8s infinite linear}@-webkit-keyframes load8{0%{transform:rotate(0deg)}100%{transform:rotate(360deg)}}@keyframes load8{0%{transform:rotate(0deg)}100%{transform:rotate(360deg)}}

.ExpertStep{margin-top:10px}.ExpertStep>p{color:#7a8386}

.Expert{margin-top:22px;display:flex;flex-wrap:wrap}.Expert .expert-back{display:flex;align-items:center;flex:0 0 100%;color:#ffc000;font-size:16px;margin-bottom:17px}.Expert .expert-back svg{width:12px;height:auto;margin-right:10px}.Expert .expert-profile{overflow:hidden;width:35px;height:35px;border-radius:50%;background-color:rgba(0,0,0,0.1);margin-right:15px}.Expert .expert-profile img{width:100%;height:100%;object-fit:cover}.Expert .expert-info{margin-right:10px}.Expert .expert-info .expert-on strong:after{content:","}.Expert .expert-info .expert-on strong:last-child:after{content:""}.Expert .expert-info .availability{display:block;margin-top:5px;color:#7a8386}.Expert .expert-arrow{margin-left:auto;margin-top:5px}.Expert .expert-arrow svg{color:#ffc000;width:13px;height:auto}

.ExpertBook{margin-top:20px;width:100%}.ExpertBook .no-slot{display:block;text-align:center;margin-top:15px}

.Calendar{border-top:2px solid #000;border-bottom:2px solid #000;padding:15px 5px}.Calendar .row-month{display:flex;justify-content:space-between;align-items:center;margin-bottom:25px}.Calendar .row-month svg{color:#ffc000;width:12px;height:auto}.Calendar .row-days{display:flex;justify-content:space-between}.Calendar .row-days .col-day{text-align:center}.Calendar .row-days .col-day.active span{background-color:#ffc000;border-radius:50%}.Calendar .row-days .col-day h3{display:block;color:#7a8386;margin-bottom:15px}.Calendar .row-days .col-day span{display:block;font-size:16px;line-height:30px;width:30px;height:30px}.Calendar .row-days .col-day span.day-off{color:#cdcdcd}

.AvailableSlot{padding-top:10px}.AvailableSlot .Card{margin-top:15px}

.RegisterStep{margin-top:5px}.RegisterStep .form-element{display:flex;flex-direction:column-reverse;height:61px;position:relative}.RegisterStep .form-element label{position:absolute;top:8px;display:none;color:#ffc000}.RegisterStep .form-element input{outline:none;width:100%;height:32px;border:none;border-bottom:2px solid #000;background-color:transparent;font-size:16px;padding-bottom:12px}.RegisterStep .form-element.complete label,.RegisterStep .form-element input:focus ~ label{display:block !important}.RegisterStep .Checkbox{margin-top:20px}.RegisterStep .Button{margin-top:10px}

.Button{width:100%;background-color:#ffc000;border:2px solid #000}.Button:disabled{color:#fff;background-color:#d4d4d4;border:2px solid #d4d4d4}

.Checkbox{display:flex;align-items:flex-end;margin-bottom:12px}.Checkbox input{position:absolute;opacity:0;margin:0}.Checkbox input.error+label:before{border:1px solid red}.Checkbox input:checked+label:before{background-color:#ffc000;border:1px solid #ffc000}.Checkbox input:checked+label:after{content:"\f00c";font-family:"Font Awesome 5 Free";font-weight:600;color:#fff;font-size:8px;position:absolute;left:4px;top:4px}.Checkbox label{cursor:pointer;position:relative;margin-bottom:0;font-size:16px;display:flex;align-items:flex-end}.Checkbox label:before{content:"";margin-top:-3px;width:16px;height:16px;border-radius:2px;border:1px solid #ffc000;margin-right:7px}.Checkbox a{margin-left:5px;color:#ffc000;font-size:12px;font-weight:700;text-decoration:underline}

.PlatformStep{margin-top:7px}.PlatformStep>p{display:block;margin-bottom:10px;color:#7a8386}

.ResumeBooking{margin-top:25px;padding-left:29px;padding-right:30px}.ResumeBooking h1{display:block;margin-bottom:10px}.ResumeBooking h3{display:inline-block;line-height:1.2}.ResumeBooking .resumeBody{margin-top:15px;border-top:2px solid #000;border-bottom:2px solid #000;padding:10px 0}.ResumeBooking .resumeBody li{display:flex;align-items:flex-start;padding:12px 0}.ResumeBooking .resumeBody li .resumeProfile{overflow:hidden;width:35px;height:35px;background-color:rgba(0,0,0,0.15);border-radius:50%}.ResumeBooking .resumeBody li .resumeProfile img{width:100%;height:100%;object-fit:cover}.ResumeBooking .resumeBody li svg{margin-left:7px;margin-right:8px;width:20px;height:auto}.ResumeBooking .resumeBody li .resumeLiInfo{margin-left:15px}.ResumeBooking .resumeBody li .resumeLiInfo .expert-on strong:after{content:","}.ResumeBooking .resumeBody li .resumeLiInfo .expert-on strong:last-child:after{content:""}.ResumeBooking .resumeBack{cursor:pointer;margin-top:25px;display:flex;align-items:center;color:#ffc000}.ResumeBooking .resumeBack svg{width:15px;height:auto}.ResumeBooking .resumeBack strong{padding-left:10px;font-size:16px}

