@import "./../../../styles/variables";

.Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid $border-color;

  h3 {
    font-size: 14px;
  }

  .close {
    text-align: center;
    width: 28px;
    height: 28px;
    line-height: 30px;
    background-color: $border-color;
    cursor: pointer;

    svg {
      color: $primary;
    }
  }
}
