@import "./../../../styles/variables";

.Button {
  width: 100%;
  background-color: $primary;
  border: 2px solid $border-color;

  &:disabled {
    color: #fff;
    background-color: #d4d4d4;
    border: 2px solid #d4d4d4;
  }
}
