@import "./../../../styles/variables";

.Spinner,
.Spinner:after {
  border-radius: 50%;
  width: 35px;
  height: 35px;
}
.Spinner {
  margin-top: 15px;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 4px solid #fff;
  border-right: 4px solid #fff;
  border-bottom: 4px solid $primary;
  border-left: 4px solid $primary;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 0.8s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
